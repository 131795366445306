import {Col} from "react-bootstrap";
import {Box, Typography} from "@mui/material";
import React from "react";

type Props = {
    left: string,
    right: string,
    discounted?: string,
    fontWeight?: string
    type: "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "subtitle1" | "subtitle2" | "body1" | "body2" | "overline" | undefined
}

function LeftRightEntry(props: Props) {
    return(
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Col xs style={{textAlign:"left"}}>
                <Typography style={{fontWeight: props.fontWeight}} variant={props.type}>{props.left}</Typography>
            </Col>
            <Col xs style={{textAlign:"right"}}>
                {
                    props.discounted ?
                        <>
                            <Typography variant="h6" style={{
                                textDecoration: "line-through",
                                color: "var(--button-bg-color)"
                            }}>{props.discounted}</Typography>
                            <strong><Typography variant="h6" style={{
                                    color: "var(--button-bg-color)"
                            }}>{props.right}</Typography></strong>
                        </>
                        :
                        <strong><Typography variant="h6" style={{
                            fontWeight: props.fontWeight,
                            color: "var(--button-bg-color)"
                        }}>{props.right}</Typography></strong>
                }

            </Col>
        </Box>
    );
}

export default LeftRightEntry;