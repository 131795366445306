import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {ShoppingCart} from "@mui/icons-material";
import {IBookThumbnail} from "../../services/model/Books";
import {isAuthenticated} from "../../services/authStorageService";
import {addToUserBag} from "../../services/bagService";
import {Link} from "react-router-dom";

type Props = {
    isDetailsPage: boolean,
    book: IBookThumbnail
};

export default function AddToBagComponent(props: Props) {
    const [addedBookName, setAddedBookName] = useState('');
    const [showModalAuth, setShowModalAuth] = useState(false);
    const [show, setShow] = useState(false);

    function addBookToBag(book: IBookThumbnail) {
        if (!isAuthenticated()) {
            setShowModalAuth(true);
            return;
        }

        addToUserBag({itemId: book.id, itemType: 'BOOK'}).then(() => {
            setAddedBookName(book.name);
            setShow(true);
        });
    }

    function handleClose() {
        setShow(false);
    }

    function handleCloseAuth() {
        setShowModalAuth(false);
    }

    function getShow() {
        return show;
    }


    return (
        <>
                <>

                    <div className="fauna-one-regular"
                         style={{
                             marginTop: 'auto',
                             marginBottom: '20px',
                             display: "flex",
                             justifyContent: props.isDetailsPage ? "flex-start" : "space-evenly",
                             alignItems: 'center'
                         }}>
                        {props.book.salePrice !== props.book.catalogPrice && props.book.salePrice !== undefined ?
                            <div className={props.isDetailsPage ? "price-big" : "price"}>
                                <s>{props.book.catalogPrice.toFixed(2)} lei</s>
                                <strong> {props.book.salePrice.toFixed(2)} lei</strong>
                            </div>
                            :
                            <div className={props.isDetailsPage ? "price-big" : "price"}>
                                <strong>{props.book.catalogPrice.toFixed(2)} lei</strong>
                            </div>}

                        <div>
                            {!props.book.soldOut ?

                                <Button className="add-to-bag"
                                    onClick={() => {
                                        addBookToBag(props.book)
                                    }}>
                                {props.isDetailsPage ?
                                    <span><ShoppingCart sx={{color: "white"}}/> Adaugă în coș </span>
                                    :
                                    <ShoppingCart sx={{color: "white"}}/>
                                }
                                </Button>
                            :
                                <>
                                    <span><ShoppingCart sx={{color: "white"}}/>Stoc epuizat</span>
                                </>}
                        </div>


                    </div>


                    <Modal
                        size="sm"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered show={getShow()} onHide={handleClose}
                    >

                        <Modal.Body className="modal-body-scrollable">
                            Cartea <i>{addedBookName}</i> a fost adăugată în coș.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="button small-action-button" onClick={handleClose}>
                                Continuă cumpărăturile
                            </Button>

                            <Button className="button small-action-button" onClick={() => window.location.href = '/my-bag'}>
                                Finalizează comanda
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        size="sm"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered show={showModalAuth} onHide={handleCloseAuth}
                    >
                        <Modal.Body className="modal-body-scrollable">
                            <div style={{paddingTop: '15px', paddingRight: '15px', paddingLeft: '15px'}}>
                                Pe lângă cărțile fizice, comenzile pe platforma noastră îți oferă acces și la resurse
                                educaționale digitale.
                            </div>
                            <div style={{padding: '15px'}}>
                                Pentru a plasa o comandă, te rugăm să te autentifici sau să îți creezi un cont.
                                Îți mulțumim!
                            </div>
                            <Link to="/login" style={{paddingLeft: '15px'}}>
                                <Button className="button button-contact">
                                    Intră în cont
                                </Button>
                            </Link>
                        </Modal.Body>
                    </Modal>
                </>

        </>
    );
}