import Button from "@mui/material/Button";
import * as React from "react";
import {Search} from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import {Box, IconButton, InputBase, Typography} from "@mui/material";

import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

type Props = {
    color?: string
}

type SearchModalProps = {
    openSearchModal: boolean;
    handleClose: () => void;
};

const SearchModal = ({ openSearchModal, handleClose }: SearchModalProps) => {
    const [searchQuery, setSearchQuery] = useState("");

    const inputRef = useRef<HTMLInputElement>(null); // Reference for input field

    useEffect(() => {
        if (openSearchModal) {
            setTimeout(() => {
                inputRef.current?.focus(); // Ensures focus after render
            }, 100); // Small delay ensures the input is available
        }
    }, [openSearchModal]);

    const handleSearch = () => {
        if (searchQuery.trim()) {
            const encodedQuery = encodeURIComponent(searchQuery);
            window.location.href = `/books/search/${encodedQuery}`; // Full page reload
            handleClose(); // Close modal after search
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };

    return (
        <Modal open={openSearchModal} onClose={handleClose} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Box
                sx={{
                    width: "80%",
                    maxWidth: 600,
                    bgcolor: "white",
                    p: 3,
                    borderRadius: 2,
                    outline: "none",
                    position: "relative",
                    boxShadow: 24,
                    textAlign: "center",
                }}
            >
                {/*/!* Close Button *!/*/}
                {/*<IconButton*/}
                {/*    onClick={handleClose}*/}
                {/*    sx={{ position: "absolute", top: 10, right: 10 }}*/}
                {/*>*/}
                {/*    <CloseButton />*/}
                {/*</IconButton>*/}

                {/* Search Input */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "2px solid var(--button-bg-color)",
                        pb: 1,
                    }}
                >
                    <InputBase
                        placeholder="Cauta..."
                        fullWidth
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyPress={handleKeyPress}
                        inputRef={inputRef} // Set focus using useRef
                        sx={{ fontSize: "18px", pl: 1, color:"var(--button-bg-color)" }}
                        autoFocus
                    />
                    <IconButton onClick={handleSearch}>
                        <Search style={{color:"var(--button-bg-color)"}}/>
                    </IconButton>
                </Box>
            </Box>
        </Modal>
    );
};

export default function MySearch({ color = '#FFFFFF' }: Props) {
    const [openSearchModal, setOpenSearchModal] = React.useState(false);

    const handleOpen = () => setOpenSearchModal(true);

    return(
        <>
            <Button className="button-just-text" onClick={handleOpen}>
                <Search style={{ color: color}} />
            </Button>
            <SearchModal openSearchModal={openSearchModal} handleClose={() => setOpenSearchModal(false)} />

        </>
    )
}
