import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../App.css';
import {Box, Grid, Typography, useMediaQuery, useTheme} from '@mui/material';
import {Link, useParams} from "react-router-dom";
import {IBookThumbnail} from "../services/model/Books";
import {getFilteredBooks, getPublicBooks} from "../services/booksService";
import RegisterSection from "../core/components/RegisterSection";
import {
    ArrowBack, ArrowForward,
    LibraryBooksRounded,
    Park,
    ShoppingCart
} from "@mui/icons-material";
import {Button, Modal} from 'react-bootstrap'
import {addToUserBag} from "../services/bagService";
import {isAuthenticated} from "../services/authStorageService";
import BookStoreCollection from "./BookStoreCollection";

function Books() {
    const {filter, slug} = useParams();
    const observer = useRef<IntersectionObserver>();

    const [babyBooks, setBabyBooks] = useState<IBookThumbnail[]>([]);
    const [twoYearsOldBooks, setTwoYearsOldBooks] = useState<IBookThumbnail[]>([]);
    const [threeYearsOldBooks, setThreeYearsOldBooks] = useState<IBookThumbnail[]>([]);
    const [fourYearsOldBooks, setFourYearsOldBooks] = useState<IBookThumbnail[]>([]);
    const [fiveYearsOldBooks, setFiveYearsOldBooks] = useState<IBookThumbnail[]>([]);
    const [enBooks, setEnBooks] = useState<IBookThumbnail[]>([]);
    const [usborneBooks, setUsborneBooks] = useState<IBookThumbnail[]>([]);
    const [educationalBooks, setEducationalBooks] = useState<IBookThumbnail[]>([]);
    const [educationalDevelopmentBooks, setEducationalDevelopmentBooks] = useState<IBookThumbnail[]>([]);
    const [totalNumber, setTotalNumber] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isChristmasCampaign, setIsChristmasCampaign] = useState<boolean>(window.location.href.includes('/campaign/christmas'));

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    let itemsPerRow = isSm || isXs ? 6 : 4;

    const lastElementRef = useCallback(
        (node: HTMLDivElement) => {
            console.log("lastElementRef", node);
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && page < totalPages ) {
                    console.log("entries", entries[0]);
                        setPage(page + 1);
                        console.log("pageInRef: ",page);

                        getData(page, itemsPerRow)
                } else {
                    console.log("not visible", entries[0]);
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoading]
    );

    async function populateBooks(setCollection: React.Dispatch<React.SetStateAction<IBookThumbnail[]>>, page: number, size: number, age: number) {
        const pageBooks = await getPublicBooks(page, size, age);
        setCollection(prevBooks => {
            const newBooks = pageBooks.data.content.filter((book: IBookThumbnail) => !prevBooks.some(b => b.id === book.id));
            return [...prevBooks, ...newBooks];
        });
    }

    async function populateUsborneBooks(setCollection: React.Dispatch<React.SetStateAction<IBookThumbnail[]>>, page: number, size: number) {
        var pageBooks = await getFilteredBooks('publisher', 'usborne', page, size);
        setCollection(prevBooks => {
            const newBooks = pageBooks.data.content.filter((book: IBookThumbnail) => !prevBooks.some(b => b.id === book.id));
            return [...prevBooks, ...newBooks];
        });
    }

    async function populateByGenre(setCollection: React.Dispatch<React.SetStateAction<IBookThumbnail[]>>, slug: string, page: number, size: number) {
        var pageBooks = await getFilteredBooks('genre', slug, page, size);
        setCollection(prevBooks => {
            const newBooks = pageBooks.data.content.filter((book: IBookThumbnail) => !prevBooks.some(b => b.id === book.id));
            return [...prevBooks, ...newBooks];
        });
    }

    const getData = useCallback(async (page:number, size:number) => {
        setIsLoading(true);
        try {

            await populateBooks(setBabyBooks, page, size, 0);
            await populateBooks(setTwoYearsOldBooks, page, size, 2);
            await populateBooks(setThreeYearsOldBooks, page, size, 3);
            await populateBooks(setFourYearsOldBooks, page, size, 4);
            await populateBooks(setFiveYearsOldBooks, page, size, 5);
            await populateByGenre(setEnBooks, 'limbi-straine', page, size);
            await populateUsborneBooks(setUsborneBooks, page, size);
            await populateByGenre(setEducationalBooks, 'carte-educativa', page, size);
            await populateByGenre(setEducationalDevelopmentBooks, 'carte-de-dezvoltare-emotionala', page, size);

        } catch (e) {
                console.error(e)
        } finally {
            setIsLoading(false);
        }
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])

    useEffect(() => {
        getData(page, itemsPerRow);
    },[])

    return (
      <>

          <div className="App">
              <div id="wave" className="parent align-left top-section div-with-background">
                  <div className="small-container ">
                      <br/>
                      {isChristmasCampaign ?
                          <div>
                              <h2>Descoperă cele mai frumoase și captivante cărți de Crăciun și de iarnă!</h2><br/>
                              <h5>În această perioadă magică, îți oferim o selecție atent aleasă de povești care să aducă farmec, căldură și bucurie sărbătorilor tale!
                              </h5>
                          </div>
                          : <div>
                              <h2>E o zi bună pentru a descoperi o carte grozavă!</h2><br/>
                              <h5>Și cu platforma noastră, nu a fost niciodată mai ușor. Avem
                                  câteva <span className="low-highlight-purple">sute de cărți</span> care așteaptă să
                                  fie
                                  descoperite - poți să vezi detaliile,
                                  temele principale ale fiecărei cărți și să descoperi cărți pornind de la autor /
                                  editură / gen / tema dorită.
                              </h5>
                          </div>
                      }
                  </div>
              </div>
              <div className="gradient-wave-bg" style={{marginTop: '-30px'}}></div>
              <div className="App-body fauna-one-regular">
                  <BookStoreCollection moreRequest={"/books/age/0"} ageLabel={"Cărți pentru 6+ luni"} books={babyBooks} visibleItems={4}/>
                  <BookStoreCollection moreRequest={"/books/age/2"} ageLabel={"Cărți pentru 2+ ani"} books={twoYearsOldBooks} visibleItems={4}/>
                  <BookStoreCollection moreRequest={"/books/age/3"} ageLabel={"Cărți pentru 3+ ani"} books={threeYearsOldBooks} visibleItems={4}/>
                  <BookStoreCollection moreRequest={"/books/age/4"} ageLabel={"Cărți pentru 4+ ani"} books={fourYearsOldBooks} visibleItems={4}/>
                  <BookStoreCollection moreRequest={"/books/age/5"} ageLabel={"Cărți pentru 5+ ani"} books={fiveYearsOldBooks} visibleItems={4}/>
                  <BookStoreCollection moreRequest={"/books/publisher/usborne"} ageLabel={"Cărți Usborne"} books={usborneBooks} visibleItems={4}/>
                  <BookStoreCollection moreRequest={"/books/genre/carte-educativa"} ageLabel={"Cărți educative"} books={educationalBooks} visibleItems={4}/>
                  <BookStoreCollection moreRequest={"/books/genre/carte-de-dezvoltare-emotionala"} ageLabel={"Dezvoltare emoțională"} books={educationalDevelopmentBooks} visibleItems={4}/>
                  <BookStoreCollection moreRequest={"/books/genre/limbi-straine"} ageLabel={"Cărți în limba engleză"} books={enBooks} visibleItems={4}/>
              </div>
              {isChristmasCampaign ?
                  <Button className="button-contact">
                      <Link to={"/christmas-packs"} style={{color: "white"}}>
                          <Park sx={{color: "var(--button-hover-background)"}}/>
                          Achiziționează acum un pachet
                          <Park sx={{color: "var(--button-hover-background)"}}/>
                          </Link>
                      </Button>
                  :
                  <RegisterSection/>
              }
          </div>
      </>
    );
}

export default Books;
